module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicons/favicon-32x32.png","icons":[{"src":"/images/favicons/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"/images/favicons/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"/images/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/images/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"883de96cc882681c8bb7d01406379409"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
